.step-1-graphic {
  .printdialog {
    width: 100%;
    height: 220px;
    border: solid 2px black;
    border-radius: 15px;
  }
  svg {
    margin-left: 2px;
    margin-top: 17px;
    padding-right: 25px;
    width: 300px;
  }
  .date-label {
    height: 100px;
    text-align: center;
    .date-check {
      font-size: 1.2rem;
    }
  }

  .zone-info {
    background: #ffffff;
    border: 1.77px solid #000000;
    box-sizing: border-box;
    margin-left: 10px;
    padding: 5px 10px;
    width: 110px;
  }

  .order-id {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-weight: 700;
    text-align: center;
    font-size: 2.5rem;
    font-family: 'SFProDisplay-Black';
    padding-top: 16px;
  }
  .address-label {
    margin-left: 15px;
    margin-top: 10px;
    @media screen and (max-width: 1367px) {
      font-size: 1.6rem;
    }
    .packingLabelText--bold {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
