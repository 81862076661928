@import '../../../styles/_variables.scss';

.dash-widget-button {
  border: 2px solid #4a90e2;
  border-radius: 2px;
  background: #ffffff;
  font-size: 32px;
  line-height: 75%;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #4a90e2;
  cursor: pointer;
  font-weight: 700;
  padding: 26px 13px;
  width: 275px;
  position: absolute;
  bottom: 0px;
  margin: 10px;
  justify-content: center;
  @media screen and (max-width: 1367px) {
    font-size: 1.6rem;
  }
}
