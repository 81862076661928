// workflow-header-order-number styles

.workflow-order-number {
  align-items: center;
  background: white;
  border-radius: 3px;
  border: solid 3px black;
  color: black;
  display: inline-flex;
  min-height: 1.75em;
  justify-content: center;
  margin: 0 1rem;
  padding: 0.1em;
  position: relative;
  vertical-align: middle;
  min-width: 1.75em;
}

.workflow-order-number__orderID {
  font-size: 1em;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -0.05em;
}

.workflow-order-number__dateCode {
  font-size: 0.2em;
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  font-weight: 900;
}

.skuOos-notes {
  text-align: left;
  font-size: 2.1rem;
}

.done-page--end {
  margin-top: 1rem;
}
