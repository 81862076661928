.step-4-graphic {
  margin: auto;
  width: 100%;
  position: relative;
  display: block;
  margin-top: 20px;
}

.color-label {
  font-weight: 700;
  &.red {
    color: #d91b1b;
  }
  &.yellow {
    color: #8b7500;
  }
  &.green {
    color: #0e7b53;
  }
  &.blue {
    color: #2071d0;
  }
  
}
