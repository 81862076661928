$primaryColor: #4a90e2;

$greyBackground: #f2f2f2;

$successBackgroundColor: #4bde86;
$successTextColor: white;

$helpBackgroundColor: #c4ddff;
$helpTextColor: #024088;
$infoBackgroundColor: #c4ddff;
$infoTextColor: #021833;

$errorBackgroundColor: #e91e4d;
$errorTextColor: white;

$warningBackgroundColor: #ffe977;
$warningTextColor: #000000;

$sprintColor: #ffe438;
$verizonColor: #fb3c42;
$attColor: #31c7ff;
$attndesColor: #8223D2;
$coxColor: #2CC84D; 
$uscellularColor: #0731A1; 

$badgeColor: #00e678;
$subTextColor: #60676c;

// Typography Size Helpers
$text-milli: 0.7rem;
$text-centi: 0.9rem;
$text-default: 1rem;
$text-kilo: 1.25rem;
$text-mega: 1.5rem;
$text-giga: 2rem;
$text-tera: 2.5rem;
$text-peta: 3.5rem;

// Typography Weight Helpers

$text-weight-default: 400;
$text-weight-medium: 600;
$text-weight-bold: 700;

$font-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-size-desktop: 15px;
$font-size-tablet: 13px; //This will adjust fontsize and all rem units defined in the layout
$font-size-mobile: 12px; //This will adjust fontsize and all rem units defined in the layout
