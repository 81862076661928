// packing-label styles
@import '../../styles/_variables.scss';

@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended|Libre+Barcode+39+Text');
.packing-label--print {
  display: none;
}

svg > g > text {
  letter-spacing: 7px;
}

@media print {
  html,
  body,
  #root,
  .App,
  .workflow {
    height: 100% !important;
    overflow: hidden;
  }

  .workflow-header-container {
    display: none;
  }

  body,
  html,
  p {
    margin: 0.1 !important;
    padding: 0.1 !important;
  }

  @page {
    margin: 0.1 !important;
    padding: 0.1 !important;
    size: 1.5in 4in;
  }

  .packing-label--print {
    display: block;
    border-radius: 15px;
    border: solid 2px black;
    height: fit-content;
    page-break-after: always;
    width: auto;
    overflow: hidden;
  }

  .packing-label--print.printEsn {
    position: relative;
    display: flex;
    margin-top: 60px;
    justify-content: center;
    border: none;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .pp-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
  }

  .pp-col__3of4 {
    width: calc(75% - 1rem);
    margin: 0 0.5rem;
  }

  .pp-col__1of4 {
    margin: 0 0.5rem;
    width: calc(25% - 0.5rem);
    margin-right: 0;
  }

  svg {
    margin-left: 6px;
    margin-top: -5px;
    width: 100%;
    & > g > text {
      letter-spacing: 7px;
    }
  }

  .date-label {
    margin-top: -20px;
    text-align: center;
    .packingLabelText--bold {
      font-size: 1.2rem;
    }
  }

  .order-id {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-weight: 700;
    text-align: center;
    font-size: 2.5rem;
    font-family: 'SFProDisplay-Black';
    padding-top: 5px;
  }

  .zone-info {
    font-size: 1.2rem;
    border: 1.77px solid #000000;
    box-sizing: border-box;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    width: 85px;
  }

  .address-label {
    div {
      margin: 10px 15px;
      margin-top: -5px;
    }
  }

  .packingLabelText--bold,
  .packingLabelText {
    font-size: 1.8rem !important;
  }
}

.packing-label__barcode {
  font-family: 'Libre Barcode 39 Extended' !important;
}

@media print {
  .packing-label__barcode {
    font-family: 'Libre Barcode 39 Text' !important;
  }
}

.packingLabelText {
  font-family: $font-default !important;
  font-weight: normal;
}
.packingLabelText--bold {
  font-family: $font-default !important;
  font-weight: 400;
  font-size: 26px;
}
