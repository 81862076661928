.sprint-border--left {
  border-left-color: $sprintColor !important;
}

.sprint-border--top {
  border-top-color: $sprintColor !important;
}

.sprint-background {
  background: $sprintColor !important;
}

.verizon-border--left {
  border-left-color: $verizonColor !important;
}

.verizon-border--top {
  border-top-color: $verizonColor !important;
}

.verizon-background {
  background: $verizonColor !important;
}

.att-border--left {
  border-left-color: $attColor !important;
}

.att-border--top {
  border-top-color: $attColor !important;
}

.att-background {
  background: $attColor !important;
}

.attndes-border--left {
  border-left-color: $attndesColor !important;
}

.attndes-border--top {
  border-top-color: $attndesColor !important;
}

.attndes-background {
  background: $attndesColor !important;
  color: #FFFFFF;
}

.cox-border--left {
  border-left-color: $coxColor !important;
}

.cox-border--top {
  border-top-color: $coxColor !important;
}

.cox-background {
  background: $coxColor !important;
}

.uscellular-border--left {
  border-left-color: $uscellularColor !important;
}

.uscellular-border--top {
  border-top-color: $uscellularColor !important;
}

.uscellular-background {
  background: $uscellularColor !important;
  color:white;
}
