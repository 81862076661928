@import '../../../../styles/_variables.scss';
.workflow-header__content {
  align-items: center;
  display: flex;
  height: 100%;
}

// workflow-header-dl styles

.workflow-header-dl {
  text-align: center;
  margin-right: 4rem;
  display: flex;
  align-items: center;
  dt {
    font-size: $text-kilo;
    font-weight: 400;
    color: #414141;
    margin-right: 0.5rem;
    @media screen and (max-width: 1367px) {
      font-size: 1.5rem;
    }
  }
  dd {
    font-size: $text-tera;
    font-weight: 900;
    margin: 0;
    padding: 0;
  }
}

dd.workflow-header__currentOrder {
  border: solid 2px black;
  padding: 0.1em;
  display: inline-block;
  border-radius: 3px;
  min-height: 1.75em;
  min-width: 1.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  font-family: monospace;
}
