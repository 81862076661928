.consumedBanner {
  background: #e8d2f6 !important;
  border: 1px solid hsl(277, 67%, 89%) !important;

  .workflow-step__sku__number {
    font-style: normal;
    font-weight: bold;
    font-size: 3rem !important;
    letter-spacing: 3.24px;
    color: #000000 !important;
    text-align: center;
    width: 96%;
  }
}

.instockBanner {
  background: #ccf6dd !important;
  border: 1px solid #ccf6dd !important;

  .workflow-step__sku__number {
    font-style: normal;
    font-weight: bold;
    font-size: 3rem !important;
    letter-spacing: 3.24px;
    color: #000000 !important;
    text-align: center;
    width: 96%;
  }
}

.unableBanner {
  background: #d5d6da !important;
  border: 1px solid #d5d6da !important;

  .workflow-step__sku__number {
    font-style: normal;
    font-weight: bold;
    font-size: 3rem !important;
    letter-spacing: 3.24px;
    color: #000000 !important;
    text-align: center;
    width: 96%;
  }
}

.return-graphic {
  width: 100%;
  padding-top: 30px;
}
