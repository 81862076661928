// dash-sidebar-button styles
@import '../../../styles/_variables.scss';

.dash-sidebar-button {
  font-size: $text-kilo;
  background: #27ae60;
  border-radius: 3px;
  border: none;
  color: black;
  cursor: pointer;
  display: block;
  font-weight: 700;
  margin-top: 1rem;
  outline: none;
  padding: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 1367px) {
    font-size: 2.25rem;
  }
}

.dash-sidebar-button--disabled {
  @extend .dash-sidebar-button;
  background: #eeeeee;
  color: black;
  &:active,
  &:focus {
    background: #eeeeee !important;
  }
}
