@import '../../../styles/_variables.scss';

.dash-main {
  background: rgb(255, 255, 255);
  height: calc(100vh - 5.5rem);
  order: 2;
  overflow-y: auto;
  padding-top: 0;
  width: calc(100% - 304px);
  position: relative;
  @media screen and (max-width: 1367px) {
    height: calc(100% - 4.7rem);
  }
}

.dash-main-chart {
  @extend .dash-main;
  align-items: center;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.width-match {
  width: 550px;
}

.width-90 {
  width: 90%;
}

.no-chart-data {
  align-items: center;
  width: 500px;
  height: 500px;
  font-size: 32px;
  font-weight: 700;
}

.orderID {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.333333px;
  padding: 5px;
  color: #ffffff;
  background: #103562;
  border-radius: 2px;
  display: block;
  height: 32px !important;
}

.orderID__selected {
  background: #878f6c !important;
  border: 5px solid #103562 !important;
  padding-top: 0px;
}

.dash-modal {
  display: flex;
  align-content: center;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 50;
  background: rgba(246, 246, 246, 0.95);
  backdrop-filter: blur(10px);
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  &::before {
    -webkit-transition: opacity 400ms;
    background: #f2f2f2;
    content: '';
    transition: opacity 400ms;
    z-index: -1;
  }
}

.dash-modal_container {
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: 518px;
  margin: auto;
  padding: 33px;
  width: 1050px;
}

.dash-modal_header {
  display: flex;
  justify-content: space-between;
}

.dash-modal_customer-name {
  font-size: 38px;
  line-height: 125%;
  font-weight: 700;
}

.dash-modal_close-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dash-modal_customer-info {
  margin-top: 57px;
  text-align: left;

  div {
    padding-bottom: 15px;
  }
}

.dash-modal_customer-info_dt {
  font-size: 28px;
  line-height: 125%;
  margin-bottom: 25px;
}

.dash-modal_customer-info_dd {
  @extend .dash-modal_customer-info_dt;
  font-weight: 700;
}

.table-customer-name {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: row;
}

.dash-table-filter__container {
  display: flex;
  margin: 0.4rem;
}

// Client Label
.table-client-name {
  display: flex;
  align-items: center;
  background: #e6e6eb;
  border-left: 8px solid;
  border-radius: 0px 2px 2px 0px;
  height: 35px;
  justify-content: center;
  margin-left: 5px;
  margin-top: -5px;
  width: 115px;
}
