@import '../../../../styles/variables';
.step-2-graphic {
  width: 100%;
  position: relative;

  .step-2-graphic__box {
    z-index: 1;
    position: relative;
    width: 33rem;
    top: 22px;
    left: 153px;
    @media screen and (max-width: 1367px) {
      width: 40rem;
      top: 12px;
      left: 46px;
    }
    @media screen and (max-width: 1281px) {
      width: 38rem;
      left: 60px;
      top: 16px;
    }
    @media screen and (max-width: 1200px) {
      width: 43rem;
      left: 69px;
      top: 15px;
    }
  }

  .step-2-graphic__label {
    .printdialog {
      position: absolute;
      left: 451px;
      top: 60px;
      width: 380px;
      height: auto;
      border: solid 2px black;
      border-radius: 15px;
      background: white;
      @media screen and (max-width: 1367px) {
        left: 345px;
        top: 30px;
        width: 320px;
        zoom: 120%;
      }

      .date-label {
        text-align: center;
      }

      .order-id {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
          sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
        font-weight: 700;
        text-align: center;
        font-size: 2.8rem;
        font-family: 'SFProDisplay-Black';
        padding-top: 10px;
      }

      .date-check {
        font-size: 1.2rem;
      }

      .zone-info {
        font-size: $text-kilo;
        background: #ffffff;
        border: 1.77px solid #000000;
        box-sizing: border-box;
        margin-top: -10px;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 5px 10px;
        width: 105px;
      }

      .address-label > .packingLabelText--bold {
        margin-left: 14px;
        margin-bottom: 10px;
        font-size: 1.4rem;
        font-weight: 400;
        padding-bottom: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    svg {
      margin-left: 10px;
      margin-top: 5px;
      width: 100%;
    }
  }
}
