@import '../../../styles/_variables.scss';

// workflow-step styles
.workflow-main {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin: 1rem 0 1rem 0;
  // overflow: auto;
  width: 100%;
  @media print {
    display: none;
  }
}
.workflow-step {
  width: 950px;
  min-height: 625px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
// CSS Transition Group styles
.workflow-step-appear {
  opacity: 0.01;
}

.workflow-step-appear.workflow-step-appear-active {
  opacity: 1;
  transition: opacity 200ms 100ms ease-in;
}
.workflow-step-enter {
  opacity: 0.01;
}

.workflow-step-enter.workflow-step-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.workflow-step-leave {
  opacity: 1;
}

.workflow-step-leave.workflow-step-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

// END CSS Transition Group styles

.workflow-step__main {
  padding: 2rem 0rem;
  position: relative;
}
.workflow-step__header {
  margin-bottom: 2rem;
  padding: 0rem 3rem;
}
.workflow-step__number {
  font-size: $text-mega;
  margin-bottom: 0;
  @media screen and (max-width: 1367px) {
    font-weight: 500;
  }
}
.workflow-step__title {
  font-weight: 800;
  font-size: $text-peta;
  line-height: 1;
  margin-bottom: 0.5rem;
  width: 100%;

  @media screen and (max-width: 1367px) {
    font-size: 3rem;
  }

  &--full {
    width: 100%;
  }
}
.workflow-step__subtitle {
  font-size: $text-mega;
}
.workflow-step__img {
  float: right;
  margin-top: 2rem;
  margin-right: 2rem;
}
.workflow-step__content {
  font-size: $text-mega;
  width: 100%;
  padding: 0rem 3rem;
  @media screen and (max-width: 1367px) {
    font-size: 1.75rem;
  }
  &--full {
    width: 100%;
  }
  ul {
    margin-left: -0.75rem;
  }
  // li {
    // padding: 0.5rem 1rem;
    // margin-bottom: 5px;
    // display: inline-block;
    // border-radius: 3px;
    // color: #33332e;
    // background: #fffbd9;
  // }
  li:not(:first-child) {
    margin-top: 1rem;
  }
}
.workflow-step__footer {
  display: flex;
  flex-direction: row;

  & > div[data-focus-lock-disabled] {
    flex: 2;
  }
}

.workflow-step__clienttag {
  display: flex;
  align-items: center;
  border-radius: 2px 0px 0px 2px;
  height: 180px;
  justify-content: center;
  position: absolute;
  right: -50px;
  top: 25px;
  transform: rotate(180deg);
  width: 50px;
  & > label {
    font-size: 2rem;
    font-weight: 400;
    font-weight: 600;
    line-height: 125%;
    margin-bottom: 5px;
    padding-bottom: 6px;
    transform: rotate(180deg+90deg);
    white-space: nowrap;
  }
}
