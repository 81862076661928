@import '../../../../styles/_variables.scss';

.step-5-graphic {
  width: 100%;
  padding-top: 30px;
}

.workflow-step__sku {
  background: #103562;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 40px;

  @media screen and (max-width: 1367px) {
    height: 7rem;
    margin-bottom: 15px;
  }
}

.workflow-step__sku__number {
  color: white;
  font-size: $text-peta;
  font-weight: 800;
  @media screen and (max-width: 1367px) {
    font-size: 3rem;
    letter-spacing: 7px;
    font-weight: 700;
  }
}

.workflow-step__stock_indicator {
  display: flex;
  align-items: center;
  border-radius: 0px 0px 2px 2px;
  font-size: $text-kilo;
  font-weight: 600;
  height: 35px;
  justify-content: center;
  line-height: 35px;
  margin: -15px 0px 0 35px;
  text-align: center;
  width: 171px;
}

.in-stock {
  background: #00e678;
}

.out-of-stock {
  background: #e91e4d;
}

.workflow-step__messaging {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 0 4rem;
  text-align: center;
  width: 100%;
  p {
    font-size: $text-mega;
  }
}
.workflow-step__messaging__icon {
  align-self: center;
  margin-bottom: 2rem;
}

.workflow-step__messaging__title {
  font-size: $text-peta;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 2rem;
  a {
    color: #4a90e2;
    text-decoration: underline;
  }
}

.no-sku-note {
  align-items: center;
  display: flex;
  font-size: 32px;
  line-height: 125%;
  margin: 20px;
  margin-bottom: 4rem;
  text-align: center;
  justify-content: center;
}

.workflow-step__cancelled {
  height: 6rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1367px) {
    height: 4.8rem;
  }
}

.workflow-step__cancelled_info {
  display: flex;
  flex-direction: row;
  font-size: $text-peta;
  @media screen and (max-width: 1367px) {
    font-size: 2.3rem;
  }
}

.cancelled-info__labels {
  margin: 0 0.5rem;
  padding-top: 1rem;
}

.cancelled-notes {
  font-size: 32px;
  font-weight: bold;
  line-height: 125%;
}

.cancelled-subnotes {
  font-weight: 600;
}

ol > li {
  font-size: 2.1rem;
}

.cancelled-notes-steps > li {
  font-size: 1.75rem;
}
